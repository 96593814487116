import { Button } from 'bootstrap';
import React, { Component } from 'react';
import { ProgressBar } from 'react-bootstrap';
import {Bar, Doughnut} from 'react-chartjs-2';
import DatePicker from "react-datepicker";
import axios from 'axios';
import API_ENDPOINT from '../api/api';
// import "react-datepicker/dist/react-datepicker.css";

import { GoogleLogin, GoogleLogout } from 'react-google-login';

import { withRouter } from "react-router-dom";

import { useHistory } from 'react-router-dom';

//*ID CLIENT GOOGLE
const clientId="773041529066-sieb753jh4r8jp3kia4ujcl1ir5btvgf.apps.googleusercontent.com";



class LoginGoogle extends Component {


    constructor(props){

        super(props);


        this.state = {
            isLogined: false,
            accessToken: '',
          }

        this.login = this.login.bind(this);
        this.handleLoginFailure = this.handleLoginFailure.bind(this);
        this.logout = this.logout.bind(this);
        this.handleLogoutFailure = this.handleLogoutFailure.bind(this);



    }



    //***********************************/
    //*********AUTHENTICATION ***********/
    //***********************************/
    async login (response) {
      let profilInfos =  response.profileObj;
      let profilToken = response.accessToken;



      await localStorage.setItem('isLogined', true);
      await localStorage.setItem('profilInfos', JSON.stringify(profilInfos));
      await localStorage.setItem('profilToken', profilToken);
      await localStorage.setItem("dirMedia", "media" ); //*on met en localstorage le path media

      await this.getStatutAdmin(profilInfos);





    }

      //*function : on check si l'utilisateur est un administrateur sur le site
      //*ON VERIFIE 2 CONDITIONS : ADMIN EN BDD et BON MAIL INFA
      async getStatutAdmin(profilInfos){


          let mail = profilInfos.email;
          axios.get(API_ENDPOINT+"/statut/admin", {
            params: {
              mail:mail
            }
          })
          .then(async function (response) {


            //*si on a bien une entree d'administrateur en bdd
            if(response.data[0].nbCount>0){

              await localStorage.setItem('statutAdmin', true);
              //*ON REDIRIGE SUR LE SITE
              await window.location.reload();
            }else{

              await localStorage.setItem('statutAdmin', false);
              //*ON REDIRIGE SUR LE SITE
              await window.location.reload();
            }



          }).catch(function (error) {



          });


        }

    //*SI ON SE LOGOUT => deconnexion du compte google, supression des elements en localstorage, et reload redirection accueil
    async logout (response) {



      //*ON REMETS  LA PAGE DE LOGIN
       window.location.href = "/login";
       await localStorage.removeItem('isLogined');
       await localStorage.removeItem('profilInfos');
       await localStorage.removeItem('profilToken');
       await localStorage.removeItem('statutAdmin');

    }

    handleLoginFailure (response) {

      alert(JSON.stringify(response))
    }

    handleLogoutFailure (response) {

      alert('Failed to log out: ' + response)

    }


    render () {


        return(

            <div>

            {/***  TEST GOOGLE OAUTH  ***/}

            {localStorage.getItem('isLogined') ?
            <GoogleLogout
              clientId={ clientId }
              buttonText='Se déconnecter'
              onLogoutSuccess={ this.logout }
              onFailure={ this.handleLogoutFailure }
              render={renderProps => (
                <a  style={{fontWeight:"bold"}} onClick={this.logout } disabled={renderProps.disabled}>Déconnexion</a>
              )}
            >
            </GoogleLogout>: <GoogleLogin
              clientId={ clientId}
              buttonText='Se connecter avec mon compte Google'
             // scope="https://www.googleapis.com/auth/admin.directory.user.readonly"
             // scope="https://www.googleapis.com/auth/drive.readonly"

              onSuccess={ this.login }
              onFailure={ this.handleLoginFailure }
              cookiePolicy={ 'single_host_origin' }
              responseType='code,token'
            />
          }


            </div>
        )
    }



}

export default withRouter(LoginGoogle);
